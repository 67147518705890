<template>
    <div class="terms">
        <a href="/" target="_self">
            <div class="logo">
                <img class="image" src="/pictures/hunyverse-logo-onecolor-flat-1024x1024.png" />
                <div class="text">HunyVerse</div>
            </div>
        </a>
        <h1 class="title is-2">Conditions d'utilisation</h1>
						Dernière modification le 17 avril 2021
						<h2 class="title">Acceptation de ces conditions</h2>
En utilisant les sites, services et applications mobiles de HunyVerse (ensemble avec "HunyVerse"), en tant qu'invité ou utilisateur abonné, vous acceptez et consentez à être lié par ces conditions. Veuillez lire attentivement ces conditions avant de commencer à utiliser HunyVerse, ainsi que les politiques de confidentialité. Si vous n'acceptez pas ces conditions ou la <a href="privacy" target="_self">politique de confidentialité</a>  de HunyVerse, vous ne devez pas accéder ou utiliser HunyVerse.
<h2 class="title">Changements des conditions d'utilisation</h2>
HunyVerse est mis à disposition par (“HunyVerse” ou “nous”). Nous pouvons réviser et mettre à jour ces conditions de temps en temps à notre seule et unique convenance. Toutes modifications prennent effets lorsque nous les publions. Votre utilisation de HunyVerse suite à la publication de conditions d'utilisations revues signifie que vous acceptez les changements apportés.
<h2 class="title">Changements chez HunyVerse</h2>
Bien que nous espérons pouvoir toujours vous offrir HunyVerse et tout ses services, nous nous réservons le droit de modifier ou de cesser d'offrir HunyVerse ou toute partie, portion ou fonctionnalité de HunyVerse sans préavis. Nous ne saurions être tenus responsable si pour une raison quelconque, tout ou une partie de HunyVerse était indisponible à tout moment.
<h2 class="title">Informations vous concernant et sur votre accès à HunyVerse</h2>
Toutes les informations collectées sur ou par HunyVerse sont soumises à notre <a href="privacy" target="_self">politique de confidentialité</a> . En utilisant HunyVerse, vous acceptez toutes les mesures prises conformément au respect de la <a href="privacy" target="_self">politique de confidentialité</a> .
<h2 class="title">Utilisation autorisée et non autorisée</h2>
Vous pouvez utiliser HunyVerse conformément aux présentes conditions, soit pour votre usage personnel et non commercial ou pour l'usage interne de votre organisation religieuse à but non lucratif. Tous les droits qui ne vous sont pas expressément accordés nous sont réservés. Vous acceptez que vous n'êtes pas autorisés et n'utiliserez pas HunyVerse comme suit:
<ul>
	<li>de quelque manière que ce soit qui viole une loi ou un règlement fédéral, local, d'état ou international;</li>
    <li>dans le but d'exploiter, de nuire à, ou d'essayer d'exploiter ou de nuire à, un mineur de quelque manière que ce soit en lui exposant un contenu inapproprié, en demandant des informations personnelles identifiable ou d'un autre manière;</li>
    <li>pour usurper l'identité ou tenter d'usurper l'identité d'une entreprise ou d'une personne, y compris de HunyVerse ou d'un autre utilisateur de HunyVerse;</li>
    <li>pour vous livrer à toute autre conduite qui, telle que déterminée par nous, peut nuire à HunyVerse ou aux utilisateurs de HunyVerse, ou engager leur responsabilité;</li>
    <li>de quelque manière que ce soit qui pourrait désactiver, surcharger, endommager ou altérer HunyVerse ou interférer avec l'utilisation de HunyVerse par un tiers;</li>
    <li>en utilisant un robot, ou tout autre dispositif, processus ou moyen d'accéder à HunyVerse dans n'importe quel but y compris la surveillance ou la copie de n'importe quelle ressource sur HunyVerse;</li>
    <li>pour introduire des virus, chevaux de Troie, vers informatiques, bombes logiques, ou tout autre contenu malveillant ou technologiquement nuisible;</li>
    <li>pour tenter d'accéder, d'interférer, d'endommager ou de perturber n'importe quelles parties de HunyVerse ou n'importe quel serveur, ordinateur, ou base de données connectée à HunyVerse;</li>
    <li>pour attaquer HunyVerse par le biais d'une attaque par déni de service (DoS attack) ou d'une attaque par déni de service distribuée; et</li>
    <li>ou encore toute tentative d’interférer avec le bon fonctionnement de HunyVerse.</li>
</ul>
Vous acceptez également de ne pas envoyer, recevoir, charger, télécharger, utiliser ou réutiliser sciemment tout matériel qui:
<ul>
    <li>contient du contenu diffamatoire, obscène, indécent, abusif, offensant, harcelant, violent, haineux, provoquant ou autrement répréhensible;</li>
    <li>promeut du contenu sexuellement explicite ou pornographique, la violence, ou la discrimination, y compris celle basée sur la race, le sexe, la religion, la nationalité, le handicap, l'orientation sexuelle, ou l'âge;</li>
    <li>enfreint tout brevet, marque déposée, secret commercial, droit d'auteur, ou toute autre propriété intellectuelle ou droits d'une personne tierce;</li>
    <li>viole les droits légaux (y compris les droits de publicité ou de confidentialité) d'autrui ou contient du contenu qui pourrait donner lieu à une responsabilité civile ou criminelle en vertu des lois ou règlements applicables;</li>
    <li>promeut toute activité illégale, ou défend, promeut, ou assiste tout acte illégal;</li>
    <li>implique des activités commerciales ou des ventes, telles que des concours, des tirages au sort et d'autres promotions des ventes, le troc ou la publicité; et/ou</li>
    <li>donne l'impression que le contenu provient de ou est approuvé par HunyVerse ou toute autre personne ou entité, alors que ce n'est pas le cas.</li>
</ul>
<h2 class="title">Contributions d'utilisateur</h2>
HunyVerse contient des fonctionnalités interactives qui permettent aux utilisateurs de faire des publications, soumettre, publier, afficher ou transmettre à d'autres utilisateurs ou à d'autres personnes des contenus ou des produits (collectivement, les "contributions d'utilisateurs") sur ou par le biais de HunyVerse. Toutefois, nous ne nous engageons pas à examiner les contributions d'utilisateurs ou tout autre produit avant qu'ils ne soient publiés sur ou par l'intermédiaire de HunyVerse et nous ne pouvons pas garantir le retrait rapide de tout contenu répréhensible après qu'il a été publié. Ainsi, nous n'assumons aucune responsabilité pour toute action ou inaction concernant les transmissions, les communications ou le contenu fourni par un utilisateur ou un tiers.
Toutes les contributions d'utilisateur doivent être conformes à ces conditions d'utilisation. En fournissant une contribution d'utilisateur sur ou via HunyVerse, vous nous accordez, à nous et à nos fournisseurs, ainsi qu'a chacun de nos détenteurs de licences, successeurs et ayant droit respectifs, le droit d'utiliser, reproduire, modifier, exécuter, afficher, distribuer, et autrement divulguer à des tiers tout ce contenu dans le but d'offrir la fonctionnalité de HunyVerse que vous demandez ou utilisez. Vous déclarez et garantissez que vous possédez ou contrôlez la totalité des droits envers et de ces contributions d'utilisateur et que vous avez le droit d'accorder cette licence et que toutes vos contributions d'utilisateur sont et seront en accords avec ces conditions d'utilisation. Nous ne sommes pas tenus responsables du contenu ou de la véracité de toute contribution d'utilisateur publiée par vous ou tout autre utilisateur de HunyVerse.
<h2 class="title">Sécurité du compte</h2>
HunyVerse permet aux utilisateurs de créer un compte ("membre") qui nécessite de fournir certaines informations à HunyVerse. Si vous choisissez ou si nous vous donnons un nom d'utilisateur, un mot de passe ou des informations similaires dans le cadre de nos procédures de sécurité, vous acceptez de traiter ces informations de manière confidentielle et vous acceptez de nous avertir immédiatement de tout accès non autorisé à votre nom d'utilisateur, mot de passe ou toute autre violation de la sécurité. Nous nous réservons le droit de désactiver n'importe quel nom d'utilisateur, mot de passe ou autre identifiant, soit choisi par vous ou fourni par nous, à tout moment si, à notre avis, vous avez violé une disposition de ces conditions.
<h2 class="title">Surveillance et application de la loi; Conformité légale; Résiliation</h2>
Vous acceptez que nous avons le droit de:
<ul>
    <li>prendre des mesures à l’égard de tout utilisateur, de compte membre ou de la Contribution de l’utilisateur que nous jugeons nécessaire ou approprié à notre entière discrétion, si nous croyons qu’il existe une violation de ces termes, une infraction aux droits de tiers, une menace pour la sécurité personnelle des autres , ou une menace, responsabilité ou dommage à la bonne volonté associée HunyVerse ou Life.Church;</li>
    <li>prendre les mesures appropriées, y compris et sans limitation, le renvoi à l'application de la loi et la divulgation de vos informations, pour toute utilisation illégale ou non autorisée de HunyVerse; et/ou</li>
    <li>résilier ou suspendre votre accès à tout ou une partie de HunyVerse pour n'importe quelle raison, y compris, sans s'y limiter, toute violation de ces conditions.</li>
</ul>
Sans limiter ce qui précède, nous avons le droit de coopérer pleinement avec les autorités légales chargées de l'application de la loi ou une ordonnance du tribunal nous demandant ou nous ordonnant de divulguer l'identité ou d'autres informations de toute personne publiant des documents sur HunyVerse.

<p>VOUS RENONCEZ ET EXONÉREZ HUNYVERSE ET SES LICENCIÉS ET FOURNISSEURS DE SERVICES ET TOUS LEURS EMPLOYÉS, DIRIGEANTS ET ADMINISTRATEURS DE TOUTE RÉCLAMATION RÉSULTANT DE TOUTES MESURES PRISES PAR L'UNE DES PARTIES PRÉCÉDENTES PENDANT OU À LA SUITE D'ENQUÊTES JUDICIAIRES ET DE TOUTES MESURES PRISES COMME CONSÉQUENCE D'ENQUÊTES PAR HUNYVERSE OU PAR LES AUTORITÉS EN CHARGE DE L'APPLICATION DE LA LOI.</p>

<h2 class="title">Votre dépendance à l'égard de l'information</h2>
Les informations présentées sur ou via HunyVerse sont mises à disposition uniquement à des fins d'information générale. Bien que nous nous efforçons de fournir des informations sur HunyVerse dans différentes langues, les traductions du contenu de HunyVerse dans d'autres langues que le français nous sont fournies par des tiers et nous ne déclarons ni ne garantissons l'exactitude de ces traductions. Nous ne garantissons pas l'exactitude, l'exhaustivité ou l'utilité de ces informations. Toute confiance que vous accordez à ces informations est strictement à vos propres risques.
<br/><br/>
<b>Nous déclinons toute responsabilité découlant de la confiance que vous, tout autre visiteur sur HunyVerse, ou toute personne qui pourrait être informée de son contenu, accordez à ces informations.</b>
<h2 class="title">Droit de la propriété intellectuelle</h2>
HunyVerse et son contenu, ses caractéristiques et ses fonctionnalités (y compris mais non limité à toutes les informations, logiciels, textes, affichages, images, vidéos et audios, et la conception, la sélection et l'arrangement de l'application) sont la propriété de HunyVerse, ses donneurs de licence ou d'autres fournisseurs d'un tel matériaux, et sont protégés par les lois françaises et internationales sur le droit d'auteur, les marques de commerce, les brevets, les secrets commerciaux et d'autres lois sur la propriété intellectuelle ou les droits de propriété.
<h2 class="title">Logos et designs</h2>
Le nom et le logo de HunyVerse, les noms des produits et des services, designs et slogans qui s'y rapportent sont des marques et/ou des droits d'auteur de HunyVerse. Vous ne devez pas utiliser ces marques et droits d'auteur sans l'autorisation écrite préalable de HunyVerse. Tous les autres noms, logos, noms de produits et de services, designs et slogans sur HunyVerse sont des marques commerciales de leurs propriétaires respectifs.
<h2 class="title">Violation du droit d'auteur</h2>
Si vous pensez qu'une contribution d'utilisateur ou un autre contenu publié sur HunyVerse viole votre droit d'auteur ou d'autres droits, veuillez contacter notre agent désigné par courrier électronique à <a href="mailto:contact@hunyverse.com" target="_blank">contact@hunyverse.com</a> . Veuillez noter que selon la politique de HunyVerse, elle se réserve le droit de résilier les comptes d'utilisateur des contrevenants récidivistes.
<h2 class="title">Liens et services de tiers</h2>
HunyVerse peut inclure du contenu fourni par des tiers. Si HunyVerse contient des liens vers d'autres sites, des pop-ups et des ressources similaires fournies par des tiers, ces liens et ressources sont fournis uniquement pour votre commodité. Nous n'avons aucun contrôle sur le contenu de ces sites ou ressources, et n'acceptons aucune responsabilité pour eux ou pour toute perte ou dommage pouvant découler de votre utilisation de ceux-ci. Si vous décidez d'accéder à l'un des sites Web tiers liés à HunyVerse, vous le faites entièrement à vos risques et périls et sous réserve des termes et conditions d'utilisation de ces sites Web. Toutes les déclarations et / ou opinions exprimées dans ces documents, et tous les articles et réponses aux questions et autres contenus, autres que le contenu fourni par HunyVerse, sont uniquement les opinions et la responsabilité de la personne ou de l'entité fournissant ces documents. Ces documents ne reflètent pas nécessairement l'opinion de HunyVerse.
<br/><br/><br/>
<b>Nous ne sommes pas responsables envers vous ou un tiers, du contenu ou de l'exactitude de tout matériel fourni par des tiers.</b>
<h2 class="title">Exclusion de garanties</h2>
VOTRE UTILISATION DE HUNYVERSE, DE SON CONTENU ET DE TOUTE FONCTIONNALITÉS, SERVICES OU ARTICLES OBTENUS A TRAVERS HUNYVERSE EST A VOS PROPRES RISQUES, TOUS CEUX QUI SONT FOURNIS SOUS UN STATUT "TEL QUEL" OU "DISPONIBLE", SANS AUCUNE SORTE DE GARANTIE, SOIT IMPLICITE SOIT EXPLICITE. NI HUNYVERSE NI AUCUNE PERSONNE ASSOCIEE AVEC HUNYVERSE NE GARANTIE NI NE REPRESENTE L'EXHAUSTIVITE, LA SECURITE, LA FIABILITE, LA QUALITE, L'EXACTITUDE OU LA DISPONIBILITE DE HUNYVERSE. SANS LIMITER CE QUI A ETE PRECITE NI HUNYVERSE NI AUCUNE PERSONNE ASSOCIEE AVEC HUNYVERSE NE REPRESENTE NI NE GARANTIE QUE HUNYVERSE OU N'IMPORTE QUEL CONTENU DES FONCTIONNALITES OU N'IMPORTE QUEL SERVICE OU ARTICLE OBTENU PAR HUNYVERSE SOIT EXACT, JUSTE, ININTERROMPU, QUE LES DÉFAUTS SERONT CORRIGES, QUE HUNYVERSE OU LE SERVEUR QUI LE REND DISPONIBLE SOIT VIERGE DE TOUT VIRUS OU D'AUTRES COMPOSANTS NUISIBLES OU QUE HUNYVERSE OU N'IMPORTE LEQUEL DE CES SERVICES OU ARTICLE OBTENUS A TRAVERS HUNYVERSE POURRA AUTREMENT RÉPONDRE A VOS BESOINS OU VOS ATTENTES.
<p>HUNYVERSE DÉCLINE ICI TOUTE GARANTIE D'AUCUNE SORTE SOIT IMPLICITE SOIT EXPLICITE, LÉGALE OU AUTRE, INCLUANT MAIS NE LIMITANT PAS LES GARANTIES DE QUALITÉ MARCHANDE, D'ABSENCE DE CONTRE-FACON ET D'ADAPTATION A UN USAGE PARTICULIER.</p>
CE QUI PRÉCÈDE N'AFFECTE AUCUNE GARANTIE QUI NE PUISSE ÊTRE EXCLUE OU LIMITÉE EN VERTU DE LA LOI APPLICABLE.
<h2 class="title">Responsabilité limitée</h2>
EN AUCUN CAS HUNYVERSE, SES CONCÉDANTS, FOURNISSEURS DE SERVICES OU AUCUN DE SES EMPLOYÉS, AGENTS, DIRIGEANTS OU ADMINISTRATEURS NE POURRONT ÊTRE TENUS RESPONSABLES DES DOMMAGES DE QUELQUE NATURE QUE CE SOIT, SOUS UNE THÉORIE JURIDIQUE, DÉCOULANT OU EN RAPPORT AVEC VOTRE UTILISATION OU VOTRE INCAPACITÉ A UTILISER, HUNYVERSE, TOUS LES SITES WEB LIÉS, TOUT CONTENU OFFERT SUR OU PAR L'INTERMÉDIAIRE DE HUNYVERSE, OU D'AUTRES SITES WEB OU TOUT AUTRE SERVICE OU ARTICLE OBTENU PAR LE BIAIS DE HUNYVERSE, Y COMPRIS LES DOMMAGES DIRECTS, INDIRECTS, SPÉCIAUX, ACCESSOIRES OU PUNITIFS INCLUANT MAIS NON LIMITÉ À, BLESSURE PERSONNELLE, DOULEUR ET SOUFFRANCE, DÉTRESSE ÉMOTIONNELLE, PERTE DE REVENUS, PERTE DE PROFITS, PERTE D'AFFAIRES OU D'ÉCONOMIES PRÉVUES, PERTE D'UTILISATION, PERTE D'ÉCARTS D'ACQUISITION, PERTE DE DONNÉES ET MÊME CAUSÉE PAR DÉTOURNEMENT (Y COMPRIS LA NÉGLIGENCE), INFRACTION DE CONTRAT OU AUTRE, MÊME SI MÊME PRÉVISIBLE.
CE QUI PRÉCÈDE N'AFFECTE AUCUNE RESPONSABILITÉ QUI NE PUISSE ÊTRE EXCLUE OU LIMITÉE EN VERTU DE LA LOI APPLICABLE.
<h2 class="title">Indemnisation</h2>
Vous acceptez de défendre, indemniser et tenir pour innocent HunyVerse, ses affiliés, détenteurs de licences et prestataires de services, et leurs dirigeants, directeurs, employés, sous-traitants, agents, fournisseurs de licences, fournisseurs, successeurs et ayants droits respectifs, de et contre toute réclamation, responsabilité, dommage, jugements, sentence, pertes, coûts, frais ou honoraires (y compris les honoraires raisonnables d'avocats) émanant de ou liés à votre non respect des présentes Conditions d'Utilisation ou votre utilisation de HunyVerse, y compris, mais non limité à, vos contributions d'utilisateur, toute utilisation de contenu HunyVerse, services et produits autrement que comme expressément autorisé dans les présentes conditions d'utilisation ou votre utilisation de toute information obtenue de HunyVerse.
<h2 class="title">Renonciation et divisibilité</h2>
HunyVerse ne peut renoncer à ses droits en vertu des présentes conditions d'utilisation que par écrit. Si une des clauses de ces conditions d'utilisation est jugée, par un tribunal ou autre instance compétente, comme étant invalide, illégale, ou inapplicable, pour une quelconque raison, une telle clause devra être éliminée ou limitée à une mesure minimale de telle sorte que les clauses restantes de ces conditions d'utilisation puissent être pleinement appliquées.
<h2 class="title">Accord complet</h2>
Ces conditions d'utilisation et notre <a href="privacy" target="_self">politique de confidentialité</a>  constituent le seul et unique accord entre vous et HunyVerse et remplace tous les accords, déclarations et garanties, écrits ou oraux, précédents et courants concernant HunyVerse.
<h2 class="title">Vos commentaires et préoccupations</h2>
Toutes les réactions, commentaires, demandes d'assistance technique et autres communications relatives à HunyVerse doivent être adressées à <a href="mailto:contact@hunyverse.com" target="_blank">contact@hunyverse.com</a>.
    </div>
</template>

<script>

export default {
    name: 'Terms',
    mounted () {
        document.title = this.$t('terms.title')
    }
}
</script>

<style lang="stylus" scoped>
.terms {
    max-width: 1023px;
    margin: 0px auto 0px auto;
    padding: 30px 30px 30px 30px;
    text-align: left;
    font-size: 18px;
    color: #222;

    .logo {
        display: inline-flex;
        align-items: center;
        margin: 0px 0px 0px 0px;

        .image {
            max-width: 70px;
        }
        .text {
            margin: 0px 0px 0px 5px;
            font-family: 'Grand Hotel';
            font-weight: 300;
            font-size: 50px;
            color: #00aba9;
        }
    }

    .title {
        margin-top: 1.5rem;
    }
}
</style>
