<template>
    <div class="home">

        <ImageSlider class="home-slider" />
        <!--<div class="home-slider" />-->

        <div class="home-overlay">
            <div class="home-container container">

                <nav class="navbar home-header" role="navigation" aria-label="main navigation">

                    <div class="navbar-brand">
                        <div
                            role="button"
                            class="navbar-burger"
                            :class="{'is-active': burgerOpened}"
                            aria-label="menu"
                            aria-expanded="false"
                            @click="onBurgerBtnClick"
                        >
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                            <span aria-hidden="true"></span>
                        </div>
                    </div>

                    <div class="navbar-menu" :class="{'is-active': burgerOpened}">
                        <div class="navbar-start">
                            <LanguageSelector
                                @change="onLanguageChange"
                                :selected="$i18n.locale"
                            />
                        </div>

                        <div class="navbar-end">
                            <div class="navbar-item">
                                <a class="link facebook-link" href="https://www.facebook.com/hunyverse" target="_blank">
                                    <div class="header-link">
                                        <i class="icon fa fa-brands fa-facebook-square"></i>
                                        <div class="text">Facebook</div>
                                    </div>
                                </a>
                            </div>
                            <div class="navbar-item">
                                <a class="link instagram-link" href="https://www.instagram.com/hunyverse" target="_blank">
                                    <div class="header-link">
                                        <i class="icon fa fa-brands fa-instagram"></i>
                                        <div class="text">Instagram</div>
                                    </div>
                                </a>
                            </div>
                            <div class="navbar-item">
                                <a class="link tiktok-link" href="https://www.tiktok.com/@hunyverse" target="_blank">
                                    <div class="header-link">
                                        <i class="icon fa fa-brands fa-tiktok"></i>
                                        <div class="text">Tiktok</div>
                                    </div>
                                </a>
                            </div>
                            <div class="navbar-item">
                                <a class="link youtube-link" href="https://www.youtube.com/channel/UCk4pjtX6_vptiPe8fM2F4Qw" target="_blank">
                                    <div class="header-link">
                                        <i class="icon fa fa-brands fa-youtube"></i>
                                        <div class="text">Youtube</div>
                                    </div>
                                </a>
                            </div>
                            <div class="navbar-item">
                                <a class="link pinterest-link" href="https://www.pinterest.com/hunyverse" target="_blank">
                                    <div class="header-link">
                                        <i class="icon fa fa-brands fa-pinterest"></i>
                                        <div class="text">Pinterest</div>
                                    </div>
                                </a>
                            </div>
                            <div class="navbar-item">
                                <a class="link twitter-link" href="https://www.twitter.com/hunyverse" target="_blank">
                                    <div class="header-link">
                                        <i class="icon fa fa-brands fa-twitter"></i>
                                        <div class="text">Twitter</div>
                                    </div>
                                </a>
                            </div>
                            <div class="navbar-item">
                                <a class="link" href="mailto:contact@hunyverse.com" target="_blank">
                                  <div class="header-link">
                                        <i class="icon fa fa-envelope-square"></i>
                                        <div class="text">{{ $t('home.contact') }}</div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </nav>

                <div class="columns is-desktop home-content">
                    <div class="column is-one-quarter-desktop left">
                        <img class="screen" :src="`/pictures/screens/screen-001-${$i18n.locale}.jpg`" />
                    </div>
                    <div class="column right">
                        <div class="logo">
                            <img class="image" src="/pictures/hunyverse-logo-nobg-flat-1024x1024.png" />
                            <div class="text">HunyVerse</div>
                        </div>
                        <div class="slogan">{{ $t('home.slogan') }}</div>
        				<div class="legend">{{ $t('home.legend') }}</div>
                        <div class="downloads">
                            <a
                                class="android-link"
                                :href="googlePlayLink"
                                target="_blank"
                            >
                                <GooglePlayBtn />
                            </a>
                            <a
                                class="ios-link"
                                :href="appleStoreLink"
                                target="_blank"
                            >
                                <AppleStoreBtn />
                            </a>
                        </div>

                    </div>
                </div>

                <div class="home-footer">
                    <div class="copyrights" v-html="$t('home.copyrights', { year })"></div>
						<div class="legal">
                            <a class="link terms-link" href="terms" target="_blank">{{ $t('home.terms') }}</a> |
                            <a class="link privacy-link" href="privacy" target="_blank">{{ $t('home.privacy') }}</a> |
                            <a class="link press-link" href="downloads/HunyVerse.zip" target="_self">{{ $t('home.press') }}</a> |
                            <a class="link contact-link" href="mailto:contact@hunyverse.com" target="_blank">{{ $t('home.contact') }}</a>
                        </div>
                    </div>
                </div>

        </div>
    </div>
</template>

<script>
import ImageSlider from '@/components/ImageSlider.vue'
import LanguageSelector from '@/components/LanguageSelector.vue'
import GooglePlayBtn from '@/components/GooglePlayBtn.vue'
import AppleStoreBtn from '@/components/AppleStoreBtn.vue'

import Stores from '@/constants/stores'

export default {
    name: 'Home',
    components: {
        ImageSlider,
        LanguageSelector,
        GooglePlayBtn,
        AppleStoreBtn
    },
    data: () => ({
        slides: [
            ''
        ],
        burgerOpened: false
    }),
    computed: {
        googlePlayLink () {
            return Stores.GooglePlay
        },
        appleStoreLink () {
            return Stores.AppleStore
        },
        year () {
            return new Date().getFullYear()
        }
    },
    methods: {
        onLanguageChange (language) {
            this.$i18n.locale = language.id
        },
        toggleBurgerMenu () {
            this.burgerOpened = !this.burgerOpened
        },
        onBurgerBtnClick (e) {
            this.toggleBurgerMenu()
        }
    },
    beforeDestroy () {
        document.removeEventListener('click', this.onClick)
    },
    mounted () {
        document.title = this.$t('home.title')

        /* eslint-disable no-undef */
        mixpanel.register({
            platform: 'website'
        })
        mixpanel.track_links('.facebook-link', 'Facebook Click')
        mixpanel.track_links('.twitter-link', 'Twitter Click')
        mixpanel.track_links('.tiktok-link', 'Tiktok Click')
        mixpanel.track_links('.youtube-link', 'Youtube Click')
        mixpanel.track_links('.instagram-link', 'Instagram Click')
        mixpanel.track_links('.pinterest-link', 'Pinterest Click')
        mixpanel.track_links('.oothkoo-link', 'oOThkOo Click')
        mixpanel.track_links('.privacy-link', 'Privacy Click')
        mixpanel.track_links('.terms-link', 'Terms Click')
        mixpanel.track_links('.press-link', 'Press Click')
        mixpanel.track_links('.contact-link', 'Contact Click')
        mixpanel.track_links('.android-link', 'Android Click')
        mixpanel.track_links('.ios-link', 'iOS Click')
        /* eslint-disable no-undef */
    }
}
</script>

<style lang="stylus" scoped>
$mobile = 1023px;
.home {
    min-height: 100%;
    background: black;

    .home-overlay {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        overflow: auto;
        z-index: 2;

        .home-container {

            .home-header {
                height: 90px;
                background: none;

                .navbar-burger {
                    color: white;
                }
                .navbar-menu {

                    @media (max-width: $mobile) {
                        background: black;
                    }
                }
                .is-active {

                    .header-link {
                        .text {
                            color: white;
                        }
                    }
                }

                .header-link {
                    display: inline-flex;
                    align-items: center;
                    color: rgba(255, 255, 255, 0.7);

                    .icon {
                        font-size: 25px;
                    }
                    .text {
                        margin: 0px 0px 0px 10px;
                        font-size: 16px;
                    }
                }
                .header-link:hover {
                    color: #FFFFFF;
                }
            }
            .home-content {
                align-items: center;
                margin: 50px 0px 0px 0px;

                @media (max-width: $mobile) {
                    margin: 0px 0px 0px 0px;
                }

                .left {
                    text-align: center;
                    //background: red;
                }
                .right {
                    //background: blue;
                }

                .screen {
                    max-width: 100%;
                    max-height: 500px;
                    border-radius: 20px;
                    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.33);

                    @media (max-width: $mobile) {
                        display: none;
                    }
                }

                .logo {
                    display: inline-flex;
                    align-items: center;
                    margin: 0px 0px 0px 0px;

                    .image {
                        max-width: 130px;

                        @media (max-width: $mobile) {
                            max-width: 70px;
                        }
                    }
                    .text {
                        margin: 0px 0px 0px 5px;
                        text-shadow: 0 0 10px rgba(0, 0, 0, 0.33);
                        font-family: 'Grand Hotel';
                        font-weight: 300;
                        font-size: 82px;
                        color: #FFFFFF;

                        @media (max-width: $mobile) {
                            font-size: 50px;
                        }
                    }
                }
                .slogan {
                    //max-width: 800px;
                    margin: 50px auto 0px auto;
                    padding: 0px 30px 0px 30px;
                    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.33);
                    text-align: center;
                    font-size: 34px;
                    color: #FFFFFF;

                    @media (max-width: $mobile) {
                        margin: 30px auto 0px auto;
                        font-size: 18px;
                    }
                }
                .legend {
                    //max-width: 860px;
                    margin: 0px auto 0px auto;
                    padding: 0px 30px 0px 30px;
                    text-shadow: 0 1px 4px rgba(0, 0, 0, 0.33);
                    text-align: center;
                    font-size: 21px;
                    color: #c4c3c3;

                    @media (max-width: $mobile) {
                        margin: 20px auto 0px auto;
                        font-size: 12px;
                    }
                }
                .downloads {
                    //display: flex;
                    //align-items: center;
                    //flex-wrap: wrap;
                    //justify-content: center;
                    margin: 50px 0px 0px 0px;

                    .download-btn {
                        margin: 0px 0px 15px 0px;
                    }
                }
            }
            .home-footer {
                position: fixed;
                bottom: 20px;
                right: 0px;
                width: 100%;
                margin: 50px 0px 0px 0px;
                padding: 0px 30px 0px 30px;
                color: rgba(255, 255, 255, 0.7);

                @media (max-width: $mobile) {
                    position: relative;
                    bottom: 20px;
                    font-size: 14px;
                }
                @media (max-height: 780px) {
                    position: relative;
                    bottom: 20px;
                    font-size: 14px;
                }

                .legal {

                    @media (max-width: $mobile) {
                        margin: 10px 0px 0px 0px;
                    }

                    .link {
                        color: rgba(255, 255, 255, 0.7);
                    }
                    .link:hover {
                        color: #FFFFFF;
                    }
                }
            }
        }
    }
}
</style>
