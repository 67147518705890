<template>
    <div class="confirm">
        <a href="/" target="_self">
            <div class="logo">
                <img class="image" src="/pictures/hunyverse-logo-onecolor-flat-1024x1024.png" />
                <div class="text">HunyVerse</div>
            </div>
        </a>
        <div v-if="loaded" class="content">
            <div v-if="!error" class="success">
                <i class="fa-solid fa-circle-check icon-valid"></i>
                <h1>{{ $t('confirm.success.section') }}</h1>
                <div class="description">{{ $t('confirm.success.description') }}</div>
            </div>
            <div v-else class="error">
                <i class="fa-solid fa-exclamation icon-error"></i>
                <h1>{{ $t('confirm.error.section') }}</h1>
                <div class="description">
                    <article class="message is-danger message-error">
                        <div class="message-body">{{ $t('confirm.error.description') }}</div>
                    </article>
                </div>
            </div>
            <a
                class="button is-primary is-large open-btn"
                :href="storeLink"
                target="_blank"
            >
                {{ $t('confirm.open-btn') }}
            </a>
        </div>
    </div>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'

import {
    getBrowserAgent,
    isiOS
} from '@/modules/browser'

import Stores from '@/constants/stores'

const userAgent = getBrowserAgent()
const userIsiOS = isiOS(userAgent)

export default {
    name: 'Confirm',
    data: () => ({
        loaded: false,
        error: false
    }),
    computed: {
        storeLink () {
            if (userIsiOS) {
                return Stores.AppleStore
            }
            return Stores.GooglePlay
        }
    },
    methods: {
        validate () {
            const apiHost = process.env.VUE_APP_API_HOST
            const url = `${apiHost}/signup/validate`
            axios({
                url,
                method: 'get',
                params: {
                    email: this.email,
                    security: this.security
                }
            })
            .then((response) => {
                const data = _.get(response, 'data', null)
                const result = _.get(data, 'result', null)
                if (!data || !result || result != 'OK') {
                    this.setError(true)
                    return
                }
                this.setError(false)
                /* eslint-disable no-undef */
                mixpanel.identify(this.email)
                mixpanel.track('Confirmed')
                /* eslint-disable no-undef */
            })
            .catch((error) => {
                this.setError(true)
            })
        },
        setError (value) {
            this.error = value
            this.loaded = true
        }
    },
    mounted () {
        document.title = this.$t('confirm.title')

        this.email = _.get(this.$route, 'params.email', null)
        this.security = _.get(this.$route, 'params.security', null)
        if (!this.email || !this.security) {
            this.error = true
            return
        }
        this.validate()
    }
}
</script>

<style lang="stylus" scoped>
.confirm {
    padding: 30px 30px 30px 30px;
    text-align: center;
    font-size: 18px;
    color: #222;

    .logo {
        display: inline-flex;
        align-items: center;

        .image {
            max-width: 70px;
        }
        .text {
            margin: 0px 0px 0px 5px;
            font-family: 'Grand Hotel';
            font-weight: 300;
            font-size: 50px;
            color: #00aba9;
        }
    }

    .content {
        text-align: center;

        .description {
            max-width: 400px;
            margin: 0px auto 0px auto;
        }
        .success {
            .icon-valid {
                width: 150px;
                height: 150px;
                margin: 25px 0px 0px 0px;
                font-weight: 400;
                font-size: 150px;
                color: #00c4a7;
            }
        }
        .error {
            .icon-error {
                width: 150px;
                height: 150px;
                margin: 50px 0px 0px 0px;
                font-weight: 400;
                font-size: 150px;
                color: #00c4a7;
            }
            .description {

                .message-error {
                    margin: 50px 0px 0px 0px;
                }
            }
        }
    }
    .open-btn {
        margin: 50px 0px 0px 0px;
    }
}
</style>
