<template>
    <div class="privacy">
        <a href="/" target="_self">
            <div class="logo">
                <img class="image" src="/pictures/hunyverse-logo-onecolor-flat-1024x1024.png" />
                <div class="text">HunyVerse</div>
            </div>
        </a>
        <h1 class="title is-2">Politique de confidentialité</h1>
        Dernière modification le 17 avril 2021
        <h3 class="title is-5">Important: veuillez lire ceci en premier</h3>
        Il est de plus en plus difficile de nos jours de pouvoir partager et diffuser la parole de Dieu sur les réseaux sociaux. De nouveaux réseaux sociaux font leur apparition régulièrement et il devient alors très compliqué de pouvoir tous les utiliser. HunyVerse est une application mobile qui permet aux chrétiens de découvrir, partager et de diffuser la parole de Dieu avec force et facilité sur tous les réseaux sociaux afin de propager celle-ci sur la sphère digitale mondiale.
        Veuillez lire attentivement cette politique de confidentialité, car elle explique comment nous collecterons, utiliserons, partagerons et traiterons vos informations personnelles.
        <h2 class="title">Contenu</h2>
        <ul>
            <li>Bref aperçu</li>
            <li>Définitions</li>
            <li>Les informations que nous collectons et comment nous les collectons</li>
            <li>Technologies de collecte automatique de données</li>
            <li>Comment nous utilisons vos données</li>
            <li>Divulgation de vos Informations</li>
            <li>Vos droits de suppression, d'accès et de correction de vos informations</li>
            <li>Sécurité et protection</li>
            <li>Traitement des données</li>
            <li>Collecte d'Informations de tiers</li>
            <li>Changements de notre politique de confidentialité</li>
            <li>Informations de contact</li>
        </ul>
        <h2 class="title">Bref aperçu</h2>
        En accédant à HunyVerse, vous acceptez cette politique de confidentialité ainsi que ses conditions et consentez à ce que vos données soient transférées et traitées en France. Votre utilisation de HunyVerse est aussi régie par nos <a href="terms" target="_self">conditions d'utilisation</a>. Veuillez lire ces conditions tout aussi attentivement.
        Voici un résumé des informations que vous pourrez trouver dans notre politique de confidentialité, qui couvre l'ensemble des produits et services de la marque HunyVerse:
        <h2 class="title">Comment nous utilisons vos données pour rendre votre expérience sur HunyVerse plus personnelle.</h2>
        Cette politique de confidentialité décrit les types de données que nous recueillons de votre interaction avec HunyVerse, ainsi que la façon dont nous traitons ces informations pour améliorer votre expérience sur HunyVerse. Lorsque vous créez un compte HunyVerse, vous utilisez l'application, les informations que nous recueillons sont destinées à offrir une expérience plus personnalisée.
        <h2 class="title">Votre confidentialité est protégée.</h2>
        Nous accordons une attention particulière à la confidentialité des informations que vous fournissez, celles que nous collectons et nous mettons en œuvre des mesures de sécurité conçues pour protéger vos données comme indique ci-dessous. Nous ne partageons pas vos données d'identification personnelles avec des entreprises de publicité ou des réseaux de publicités tiers à des fins publicitaires tierces.
        <h2 class="title">C’est votre expérience.</h2>
        Vous avez le choix sur la façon dont HunyVerse accède, collecte, partage et stocke vos informations personnelles. Vous aurez des choix à faire à propos de l'utilisation et du traitement de vos informations lors de votre première inscription sur HunyVerse, lorsque vous utilisez certaines fonctionnalités, opérez des choix concernant les paramètres de votre compte HunyVerse.
        <h2 class="title">Vos questions et remarques sont les bienvenues.</h2>
        Vos questions et remarques concernant la présente politique de confidentialité et nos pratiques en matière de confidentialité sont les bienvenues. Si vous avez des questions ou des remarques, contactez-nous par courriel électronique à <a href="mailto:contact@hunyverse.com" target="_blank">contact@hunyverse.com</a> .

        <h2 class="title">Définitions</h2>
        Nous utilisons des formes abrégées dans le présent document, afin d'en faciliter la lecture. Par exemple, lorsque nous écrivons "HunyVerse", nous nous référons:
        <ul>
            <li>L’application mobile HunyVerse.</li>
            <li>Les sites Web de HunyVerse, tels que :
        				<ul>
        		        <li>hunyverse.com</li>
        		        <li>donate.hunyverse.com</li>
        		        <li>help.hunyverse.com</li>
        				</ul>
        		</li>
        </ul>
        Les produits HunyVerse sont détenus et exploités par HunyVerse ou "nous". HunyVerse ne peut être utilisée que par des utilisateurs enregistrés, appelés "membres".
        <h2 class="title">Les informations que nous collectons et comment nous les collectons</h2>
        Les informations que nous recueillons dépendent des services et des fonctionnalités que vous demandez. Vous pouvez refuser de nous communiquer des informations personnelles; cependant, cela peut nous empêcher de vous fournir certains services ou fonctionnalités d’ HunyVerse. Les informations personnelles que nous recueillons et le but pour lequel nous les utilisons sont décrits ci-dessous.
        <h2 class="title">Les informations personnelles que vous nous fournissez.</h2>
        <p>Vous avez besoin de créer un compte de membre pour utiliser HunyVerse. L'adhésion nous permet de personnaliser HunyVerse pour en faire une expérience plus personnelle et privée. Pour créer un compte de membre HunyVerse, nous vous demandons de fournir un prénom, un nom de famille et une adresse électronique valide. Nous utiliserons ces informations pour vous associer à votre compte de membre HunyVerse spécifique. Après avoir créé un compte de membre HunyVerse, vous pouvez également choisir de fournir votre genre, votre date de naissance, votre site web, une brève biographie, une photo de profil et d'autres informations que vous fourniriez. Toutes ces informations, si elles sont fournies, seront également associées à votre compte.</p>
        Au-delà des informations personnelles collectées pour créer un compte de membre, vous avez le choix des informations supplémentaires que vous nous fournissez. Nous recueillons des données personnelles lorsque vous les fournissez, les postez ou les téléchargez sur HunyVerse. Vous n'êtes pas obligé de fournir ces informations; cependant, si vous ne le faites pas, cela peut limiter notre capacité à personnaliser votre compte HunyVerse et votre capacité à utiliser HunyVerse dans toute son étendue.
        <h2 class="title">Vos contributions d'utilisateur.</h2>
        HunyVerse permet aux utilisateurs de publier, de diffuser et d'afficher certains contenus (nous appelons cela "publier" et le contenu "publication"). Les publications peuvent être publiés sur les plates-formes publiques, les sites web et les comptes des réseaux sociaux auxquels vous accédez par l'intermédiaire de HunyVerse, ou transmises à d'autres utilisateurs de HunyVerse ou à des tiers avec lesquels vous choisissez de vous connecter sur d'autres plateformes ou services. Par exemple, il peut s'agir de partager un verset texte ou illustré. HunyVerse vous permet également de créer certains contenus à garder sur votre compte, comme une catégorie d'un verset de biblique, dont certaines peuvent être publiés afin de les rendre disponible à d'autres membres. Nous appellerons tout le contenu que vous avez créé, qu'il soit publié ou non, des "contributions d'utilisateur".
        <p>Nous traitons vos contributions d'utilisateur afin de faciliter votre capacité à les utiliser, stocker et diffuser comme vous le souhaitez. Nous associerons vos contributions d'utilisateur à votre compte tant que vous choisirez de ne pas les supprimer. Veuillez noter que si vous divulguez publiquement des informations personnelles, que ce soit par le biais de messages collaboratifs, des réseaux sociaux, de forums de discussion ou d'autres forums publics en ligne, ces informations peuvent être collectées et utilisées par d'autres personnes.</p>
        <p><u>Les contributions d'utilisateurs sont publiées et transmises à vos propres risques.</u></p>
        Nous ne pouvons pas contrôler les actions des autres utilisateurs ou des tiers avec lesquels vous choisissez de les partager. Par conséquent, nous ne pouvons pas et ne garantissons pas que vos contributions d'utilisateur partagées ne seront pas vues ou utilisées de manière non autorisée, et nous n'acceptons aucune responsabilité associée à vos contributions d'utilisateur.
        <h2 class="title">Vos communications à notre attention.</h2>
        Nous recueillons des informations sur vous lorsque vous envoyez, recevez ou échangez des messages avec nous, y compris lorsque vous soumettez des informations personnelles ou des demandes par courrier électronique à <a href="mailto:contact@hunyverse.com" target="_blank">contact@hunyverse.com</a> . Nous conservons ces communications pour traiter vos requêtes, répondre à vos demandes et améliorer HunyVerse et nos services.
        <h2 class="title">Technologies de collecte automatique de données</h2>
        En acceptant notre politique de confidentialité, vous acceptez l'utilisation de cookies et technologies similaires (par exemple, des balises web, des pixels, des tags et des identifiants d'appareils que nous appelons collectivement «cookies») comme décrites dans cette politique. Si vous utilisez HunyVerse sans changer les paramètres de votre navigateur ou appareil pour désactiver les cookies, nous supposerons que vous acceptez de recevoir tous les cookies fournis via HunyVerse.
        <h2 class="title">Cookies et autres technologies similaires.</h2>
        Nous utilisons des cookies pour vous reconnaître et/ou votre ou vos appareils allumés et éteints au travers des différentes applications HunyVerse. Les cookies facilitent la meilleure utilisation personnelle possible de HunyVerse, car ils nous permettent de vous reconnaître et de préserver vos préférences d'utilisation d'une session à l'autre, nous aident à sécuriser votre compte, et améliorent en général la fonctionnalité des produits et services proposés par HunyVerse. Ils nous aident également à nous assurer que les informations personnelles sont bien utilisées avec le bon compte. Surtout, nous n'utilisons PAS de cookies ou de technologies similaires pour faciliter les publicités basées sur les intérêts des biens et services de tiers.
        Nous utilisons des cookies pour recueillir des détails sur votre utilisation de HunyVerse (y compris les données de trafic, les données de localisation IP, les journaux, le type de navigateur, la langue du navigateur, la fonctionnalité demandée et le moment de vos demandes), ainsi que d'autres données de communication et les ressources auxquelles vous accédez, que vous utilisez et que vous créez sur ou par le biais de HunyVerse. Nous utilisons ces informations pour vous offrir une expérience HunyVerse personnelle et pour communiquer avec vous plus efficacement. Les informations sont également collectées pour déterminer le nombre total de dispositifs uniques utilisant HunyVerse et/ou des parties de HunyVerse, suivre l'utilisation totale, analyser les données d'utilisation et améliorer les fonctionnalités de HunyVerse pour tous les membres et visiteurs. Nous pouvons combiner ces informations afin de vous offrir une meilleure expérience et d'améliorer la qualité de notre service. En général, nous conservons les données que nous collectons à partir des cookies pendant 21 jours, mais nous pouvons les sauvegarder pendant une période plus longue si nécessaire, par exemple lorsque la loi l'exige ou pour des raisons techniques.
        Bien que la plupart des navigateurs internet acceptent les cookies par, vous pouvez les contrôler via les paramètres de votre navigateur et outils semblables, et accepter de refuser tous les cookies. Vous pourriez refuser d'accepter les cookies en activant le paramètre approprié sur votre navigateur ou appareil. Mais si vous le faites, vous serez dans l'incapacité d’accéder à certaines parties de HunyVerse, nous interdisant de fournir toutes les capacités de HunyVerse et empêchant l'utilisation de certaines fonctionnalités et services qui ont besoin de ces technologies.
        HunyVerse ne saurait réglementer d'autres sites, contenus ou applications liés ou encore divers sites et services fournis par des tiers, y compris le fabricant de votre appareil et votre fournisseur de services mobiles. Ces tiers peuvent placer leurs propres cookies ou d'autres fichiers sur votre ordinateur, collecter des données ou vous demander des informations personnelles. Les informations qu'ils recueillent peuvent être associées à vos informations personnelles, y compris des informations sur vos activités en ligne au fil du temps et sur différents sites web, applications et autres services en ligne. Ces tiers peuvent utiliser ces informations pour vous fournir un contenu ciblé (comportemental) basé sur vos intérêts. Nous ne contrôlons pas les technologies de suivi de ces tiers ni la manière dont ils peuvent être utilisés. Si vous avez des questions sur les contenu ciblés, vous devez contacter directement le fournisseur.
        <h2 class="title">Fournisseurs d'attribution.</h2>
        Nous faisons la publicité de HunyVerse sur des sites tiers tels que Facebook et Google et utilisons des kits de développement de logiciels tiers (SDK) pour attribuer un téléchargement de HunyVerse à la publicité placée sur le site tiers. Nous pouvons fournir à ces tiers des informations agrégées et dépersonnalisées concernant les publicités placées sur leurs sites et les téléchargements de HunyVerse qui en résultent. Nous ne vendons pas vos informations personnelles à des tiers et n'autorisons pas nos fournisseurs de services SDK à vendre vos informations personnelles à des tiers ou à les utiliser pour solliciter la vente de biens ou de services de tiers.
        <h2 class="title">ID des appareils, localisation et accès au réseau.</h2>
        Lorsque vous accédez ou quittez les sites web de HunyVerse, nous recevons l'URL du site d'où vous venez et de celui que vous visitez ensuite. Nous obtenons également des informations sur votre serveur proxy, votre système d'exploitation, votre navigateur web et ses modules complémentaires, l'identifiant et les caractéristiques de votre appareil, et/ou votre fournisseur d'accès Internet ou votre opérateur de téléphonie mobile lorsque vous utilisez HunyVerse. Nous recevons également des données provenant de vos appareils et réseaux, y compris votre adresse IP.
        Nous utilisons les adresses IP que nous recueillons auprès de nos utilisateurs pour les traiter avec des informations publiques sur la latitude et la longitude relatives à votre fournisseur d'accès Internet ou de services mobiles afin de déterminer et, dans certains cas, de représenter de manière globale et dépersonnalisée la position géographique approximative pour chaque cas d'utilisation de HunyVerse. Nous conservons les informations sur la latitude et la longitude à des fins de dépannage et de diagnostic, mais ne sont jamais associées à des informations vous concernant ou permettant de vous identifier personnellement.
        <h2 class="title">Comment nous utilisons vos données</h2>
        Nous utilisons les données que nous recueillons de vous, celles que vous nous fournissez ainsi que les déductions que nous faisons à partir de ces informations pour:
        <ul>
        	<li>Fournir, soutenir et personnaliser HunyVerse et la fonctionnalité HunyVerse que vous sollicitez;</li>
            <li>Créer, maintenir, personnaliser et sécuriser votre compte HunyVerse, le cas échéant;</li>
            <li>Traiter vos demandes et répondre à vos requêtes;</li>
            <li>Fournir des informations sur d'autres produits ou services de HunyVerse;</li>
            <li>Maintenir la sûreté, la sécurité et l'intégrité de HunyVerse et de l'infrastructure qui facilite l'utilisation de HunyVerse ;</li>
            <li>Le développement interne de HunyVerse et de nos autres produits et services;</li>
            <li>Une analyse interne concernant l'utilisation de HunyVerse;</li>
            <li>Pour répondre à tout autre objectif pour lequel vous les fournissez;</li>
            <li>Pour nous acquitter de nos obligations et faire valoir nos droits en vertu de la loi applicable, y compris nos droits et obligations en vertu de la présente politique de confidentialité et nos <a href="terms" target="_self">conditions d'utilisation</a> ;</li>
            <li>De la manière décrite dans la présente politique de confidentialité;</li>
            <li>De toute autre manière décrite au moment où vous fournissez les informations; et</li>
            <li>Pour tout autre usage autorisé par vous.</li>
        </ul>
        <h2 class="title">Adhésion.</h2>
        Nous utilisons les informations personnelles que vous nous fournissez pour créer et maintenir votre compte de membre. Nous utilisons également ces informations pour autoriser et authentifier votre accès à votre compte. Nous conservons ces informations et celles relatives à votre utilisation de HunyVerse en relation avec votre compte aussi longtemps que vous êtes membre. Par exemple, votre nom, prénom et votre adresse électronique seront stockés et utilisés par nous en relation avec votre nom de compte ou votre identifiant de compte généré automatiquement afin de vous authentifier en tant qu'utilisateur correct de votre compte. Nous stockerons également vos contributions d'utilisateur en association avec votre compte de membre afin de vous permettre d'accéder, de ré-accéder, de publier et d'utiliser vos contributions d'utilisateur comme vous le souhaitez.
        <h2 class="title">Le contenu HunyVerse.</h2>
        Nous enregistrons la manière dont vous utilisez HunyVerse et son contenu, tels que vos recherches, les versets textes et illustrés auxquels vous accédez ou likez, ainsi que la langue dans laquelle vous choisissez d'utiliser ce contenu. Nous recueillons et stockons également les contributions d'utilisateurs que vous créez, tels que les catégories. Nous traitons ces informations pour vous permettre d'accéder et d'utiliser le contenu que vous créez ou auquel vous souhaitez accéder par le biais de chaque session sur HunyVerse.
        Vous aurez le choix de télécharger ou non certains contenus HunyVerse et contributions d'utilisateurs sur votre appareil. Vous aurez le choix d'autoriser ou non l'accès à HunyVerse au stockage de votre appareil pour ajouter et modifier ce contenu téléchargé. L'accès au stockage de votre appareil est uniquement utilisé par HunyVerse pour télécharger le contenu demandé sur votre appareil.
        Vous avez le choix de créer, d'accéder ou de stocker les informations et le contenu de HunyVerse. Si vous le faites, nous les stockerons en association avec votre compte de membre. Nous pourrions utiliser les informations concernant votre utilisation de HunyVerse et les déductions que nous faisons de cette utilisation pour vous recommander d'autres contenus de HunyVerse. Par exemple, sur la base d'une recherche de verset ou de catégories, nous pouvons vous suggérer des versets et catégories supplémentaires. Vous pouvez choisir de ne pas recevoir ces recommandations par courrier électronique.
        <h2 class="title">Vos communications à notre attention.</h2>
        Nous communiquons avec vous par le biais des notifications push, par courrier électronique. Si nous communiquons avec vous par courrier électronique, vous pouvez généralement vous désabonner par le lien de désabonnement figurant dans les courriers électroniques. Vous pouvez également adapter vos paramètres de notification à tout moment dans le menu paramètres de l'application HunyVerse.
        <h2 class="title">Recommandations</h2>
        Nous utilisons les données que nous recueillons de vous ainsi que les déductions que nous en faisons pour vous recommander certains contenus, fonctionnalités, produits et services supplémentaires offerts par HunyVerse.  Nous pouvons vous contacter par le biais d'une notification push, d'un courriel ou de message dans l'application pour discuter de ces recommandations et la manière d'utiliser HunyVerse ainsi que d'autres messages concernant HunyVerse.
        <h2 class="title">Problèmes de sécurité, juridiques et techniques</h2>
        Nous pouvons utiliser vos données personnelles pour enquêter, répondre et résoudre des problèmes juridiques, de sécurité, techniques et des plaintes concernant HunyVerse et, si nécessaire, à des fins de sécurité ou pour enquêter sur d'éventuelles fraudes, violations de la loi, violations de nos <a href="terms" target="_self">conditions d'utilisation</a> , politiques de confidentialité ou tentatives de nuire à soi-même ou aux autres. Nous pouvons utiliser vos données pour vous contacter au sujet de la sécurité des comptes, des questions juridiques et d'autres questions liées au service. Sachez que vous ne pouvez pas refuser de recevoir de tels messages de notre part.
        <h2 class="title">Avis</h2>
        Nous pouvons utiliser vos données personnelles pour vous informer d'un incident de sécurité ou d'une violation de données en: (i) envoyant un message à l'adresse électronique que vous nous avez fournie (le cas échéant); (ii) publiant sur une page de HunyVerse destinée au public ou par le biais d'un message dans l'application; (iii) envoyant des messages par le biais des principaux médias d'État; et/ou (iv) par téléphone, y compris des appels et/ou des SMS automatisés. Des tarifs standard pour les SMS et les données mobiles peuvent être imposés par votre opérateur de téléphonie mobile. Les avis envoyés par courrier électronique seront effectifs au moment où nous les envoyons, les avis que nous envoyons par des publications seront effectifs lorsqu'elles sont publiées, ceux envoyés par message dans l'application lorsque le message est envoyé et ceux que nous envoyons par téléphone seront effectifs lorsqu'ils sont transmis ou composés. Vous acceptez de recevoir des communications électroniques d’HunyVerse et à votre utilisation et accès aux services fournis par HunyVerse. Il est de votre responsabilité de tenir à jour votre adresse électronique et toute autre information nous permettant de vous contacter afin que nous puissions vous transmettre ces communications.
        <h2 class="title">Analyse et performance.</h2>
        Nous analysons en interne les données personnelles dont nous disposons, l'utilisation du contenu de HunyVerse, ainsi que les déductions que nous faisons à partir de ces données, afin d'observer les tendances sociales, économiques et géographiques en ce qui concerne le contenu de HunyVerse. Dans certains cas, nous travaillons avec des tiers dignes de confiance pour effectuer ces recherches, sous des contrôles conçus pour protéger votre vie privée. Nous pouvons divulguer l'utilisation du contenu de HunyVerse de manière agrégée avec des informations dépersonnalisées et anonymisées qui ne divulguent aucun utilisateur particulier ou les informations d'identification personnelle de cet utilisateur. Par exemple, nous pouvons utiliser vos données pour générer des statistiques sur l'utilisation globale de HunyVerse dans le monde entier ou dans des régions géographiques spécifiques.
        Nous exploitons des données utilisateurs anonymisées et agrégées pour commercialiser HunyVerse, y compris dans des communications qui promeuvent l'adhésion à HunyVerse et la croissance du réseau en célébrant, par exemple, le nombre total d'installations mobiles de HunyVerse. Nous utilisons des données, dont des données personnelles fournies par les utilisateurs, des données utilisateurs agrégées, des données recueillies lors de l'utilisation de HunyVerse (historique de recherche, nombre de versets likés, activité de partages sur les réseaux sociaux p. ex.), des informations déduites à partir de ces données pour nos opérations internes de recherche et de développement, dans le but d'offrir une meilleure expérience globale de HunyVerse, de mesurer la performance de HunyVerse et d'augmenter l'utilisation de HunyVerse et de ses fonctionnalités. Pour ce faire, nous procédons à des modifications de HunyVerse communément accessibles ou envoyons par l'intermédiaire de HunyVerse des messages aux utilisateurs pour leur suggérer des fonctionnalités et des contenus de HunyVerse.
        <h2 class="title">Données sensibles</h2>
        En téléchargeant et en utilisant HunyVerse, nous ne supposons pas que vous êtes d'une confession religieuse particulière ou que vous nous exprimez une croyance religieuse particulière ; nous supposons simplement que vous êtes intéressé par le contenu que nous fournissons. Nous n'exigeons pas que les utilisateurs nous fournissent des informations sur ces croyances ou nous communiquent d'autres données sensibles à propos des races, des origines ethniques, des croyances philosophiques, de l'état de santé physique ou mentale avant d'utiliser HunyVerse, de créer ou maintenir un compte de membre HunyVerse. Vous aurez la possibilité, avec certaines fonctionnalités de HunyVerse, de créer, stocker et partager des pensées et des messages liés au contenu de HunyVerse, y compris la possibilité d'enregistrer et de partager des catégories de versets. Il vous appartient de choisir si vous souhaitez fournir des informations sensibles dans les contributions d'utilisateur que vous créez. Si vous choisissez de fournir des informations sensibles, nous pouvons utiliser ces informations avec d'autres informations non sensibles que vous fournissez afin de vous donner une expérience HunyVerse plus personnalisée et de vous fournir des services et opérations que vous demandez par le biais de HunyVerse, tels que le partage ou le stockage de contenu. En tout état de cause, HunyVerse ne traitera les données sensibles que vous fournissez pour ses activités légitimes en votre nom et uniquement en conformité avec les termes de cette politique et de toute demande supplémentaire que vous ferez au sujet de ces informations. Nous conserverons également vos informations sous réserve des garanties appropriées discutées dans cette politique. Si vous avez des questions, n'hésitez pas à nous contacter à <a href="mailto:contact@hunyverse.com" target="_blank">contact@hunyverse.com</a> .
        <h2 class="title">Divulgation de vos Informations</h2>
        Nous ne vendons ni ne partageons vos données personnelles avec des entreprises de publicités ou des réseaux publicitaires tiers pour leurs besoins publicitaires.
        <h2 class="title">Divulgation en votre nom.</h2>
        Nous pouvons divulguer des informations personnelles que nous collectons ou que vous fournissez comme décrit dans cette politique de confidentialité pour remplir nos obligations en vertu de nos conditions d’Utilisation, le but pour lequel vous les fournissez, pour toute autre raison, vous demander lorsque vous fournissez des informations, ou pour toute autre fin pour laquelle nous avons votre consentement.
        <h2 class="title">Divulgation par vous.</h2>
        Lorsque vous partagez des informations via HunyVerse, ces informations sont visibles par vous et par toute autre personne avec qui vous choisissez de les partager. Si vous donnez accès à votre compte HunyVerse à d'autres applications et services, en fonction de votre approbation, ces services auront alors accès à vos données partagées. L'usage, la collecte et la protection de vos données par ces services tiers sont sujets à leurs politiques.
        <h2 class="title">Divulgation interne.</h2>
        Nous traiterons vos données personnelles en interne afin d'aider à combiner les informations personnelles couvertes par les différents aspects de HunyVerse et nos autres produits et services dans le but de vous fournir des services personnalisés et utiles à vous et aux autres.
        <h2 class="title">Les fournisseurs de services.</h2>
        Nous pouvons diffuser des informations personnelles que nous collectons ou que vous fournissez à nos contractants, fournisseurs et services tiers que nous utilisons dans le seul cadre du soutien de notre mission (tels que l'hébergement, la maintenance, l'analyse, l'audit, les paiements, la détection de fraude, la communication et le développement). Par exemple, certaines plateformes de HunyVerse utilisées pour l'envoi d’e-mail ou de notifications sont créées et gérées par des tiers, de sorte que certaines de vos informations leur sont envoyées en toute sécurité pour des raisons de fonctionnalité. Ils auront accès à vos informations nécessaires de manière raisonnable pour accomplir ces tâches de votre part et sont tenus de ne pas les divulguer ou de les utiliser à d'autres fins.
        <h2 class="title">Procédure juridique.</h2>
        Il est possible que nous devions divulguerions des informations vous concernant lorsque la loi, une assignation à comparaître ou une autre procédure judiciaire l'exige. Nous nous efforçons d'informer les utilisateurs des demandes légales concernant leurs données personnelles lorsque notre jugement le justifie, sauf si la loi ou une décision de justice l'interdit ou si la demande est urgente. Nous pouvons contester de telles demandes lorsque nous estimons, à notre discrétion, que les demandes sont trop larges, vagues ou qu'elles ne sont pas suffisamment autorisées, mais nous ne promettons pas de contester chaque demande. Nous pourrions également divulguer vos informations si nous pensons en toute bonne foi que la divulgation est raisonnablement nécessaire pour (i) enquêter, prévenir ou prendre des mesures concernant des activités illégales suspectes ou réelles ou pour aider les agences gouvernementales chargées de faire respecter la loi ; (ii) faire respecter nos accords avec vous ; (iii) enquêter et nous défendre contre toute réclamation ou allégation de tiers ; (iv) protéger la sécurité ou l'intégrité de HunyVerse ; (v) exercer ou protéger les droits et la sécurité de HunyVerse, des utilisateurs, de notre personnel ou d'autres personnes. Nous nous réservons également le droit de divulguer confidentiellement les informations personnelles que nous avons sur vous dans le cadre d'une fusion ou d'une acquisition potentielle ou réelle, telle que la vente de tous ou de la quasi-totalité de nos actifs.
        <p><u>Vos droits de suppression, d'accès et de correction de vos informations</u></p>
        <h2 class="title">Comment créer vos demandes.</h2>
        Pour les données personnelles que nous avons sur vous, vous pouvez demander ce qui suit:
        <ul>
        		<li>Suppression: Vous pouvez nous demander d'effacer ou de supprimer toutes ou une partie de vos données personnelles. Veuillez noter que cela peut limiter votre capacité à utiliser certaines fonctionnalités de HunyVerse. Veuillez noter qu'une adresse e-mail est requise pour avoir un compte membre.</li>
            <li>Correction/Modification: Vous pouvez modifier certaines de vos données personnelles via votre compte ou nous demander de modifier, mettre à jour ou corriger vos données dans certains cas, y compris si elles sont inexactes.</li>
            <li>S'opposer à, limiter ou restreindre l'utilisation des données: Vous pouvez nous demander de cesser d'utiliser tout ou une partie de vos données personnelles ou d'en limiter l'utilisation.</li>
            <li>Droit d'accès et/ou de modifier de vos données: Vous pouvez nous demander une copie ou une divulgation des données à caractère personnel que vous nous avez fournies.</li>
        </ul>
        Certaines lois peuvent prévoir le droit de faire cela et d'introduire d'autres demandes supplémentaires concernant vos informations personnelles. Si vous nous avez fourni des informations personnelles et que vous souhaitez faire une telle demande en vertu de la loi d'une région spécifique, veuillez envoyer votre demande à <a href="mailto:contact@hunyverse.com" target="_blank">contact@hunyverse.com</a>  et inclure la phrase "[Votre état/pays] demande de confidentialité" dans l'objet.
        <p>Pour faire ces demandes ou toute autre demande concernant vos informations personnelles, vous pouvez envoyer un courrier électronique à <a href="mailto:contact@hunyverse.com" target="_blank">contact@hunyverse.com</a> .</p>
        Nous demandons aux personnes qui font des demandes de s'identifier avec au moins leur nom, adresse, courrier électronique et d'identifier les informations dont l'accès, la correction ou la suppression est demandé avant de traiter toute demande. Nous pouvons refuser de traiter les demandes si nous ne pouvons pas vérifier l'identité du demandeur, si nous pensons que la demande mettra en danger la vie privée d'autres personnes, si nous pensons que la demande violerait une loi ou une exigence légale, si nous pensons que la demande rendrait les informations incorrectes, ou pour un objectif légitime similaire.
        <p>Nous n'exercerons aucune discrimination à votre encontre dans l'exercice de vos droits en vertu de la loi en vigueur. Nous ne facturons pas de frais pour traiter ou répondre à votre demande vérifiable, sauf si celle-ci est excessive, répétitive ou manifestement non fondée. Si nous estimons que la demande nécessite des frais, nous vous indiquerons les raisons de cette décision et vous fournirons une estimation des coûts avant de traiter votre demande.</p>
        <h2 class="title">Suppression de votre compte.</h2>
        Si vous choisissez de fermer votre compte ou si vous nous demandez de modifier ou de supprimer tout ou partie de vos informations personnelles, nous conserverons vos données personnelles si nous avons le droit ou l'obligation légale de conserver ces informations ou de satisfaire aux exigences réglementaires, de résoudre des litiges, de maintenir la sécurité, de prévenir la fraude et les abus, de faire valoir nos droits ou de répondre à toute autre demande de votre part (par exemple, pour refuser de recevoir d'autres messages ou une copie de vos données). Dans le cas contraire, si vous nous demandez de fermer votre compte, nous supprimerons votre compte et toutes les informations que nous possédons qui sont associées à votre compte, à l'exception des statistiques agrégées basées sur des informations dépersonnalisées et des déductions que nous faisons à partir de ces dernières. Nous conserverons également notre décompte global du nombre total d'applications HunyVerse téléchargées, qui inclurait le fait que vous avez téléchargé HunyVerse, sans conserver les informations personnelles associées à ce compte.
        <p>Nous n'avons aucun contrôle sur les informations que vous avez partagées avec d'autres utilisateurs via HunyVerse après la fermeture de votre compte ou la suppression d'informations ou la tentative de suppression de votre compte. Vos informations et les contenus que vous avez partagés peuvent continuer à s'afficher dans les services d'autres personnes (par exemple, les résultats des moteurs de recherche) jusqu'à ce qu'ils actualisent leur cache.</p>
        <h2 class="title">Sécurité et protection</h2>
        Nous utilisons les mesures de sécurité standard conçues pour protéger vos données. Cela inclut l'utilisation du cryptage pour vos données pendant leur transmission entre votre appareil ou votre navigateur et nos serveurs et aussi lorsqu'elles sont au repos. Les données fournies par HunyVerse sont également stockées dans un système de gestion d'infrastructure certifié ISO 27017, ce qui signifie qu'il a été jugé conforme aux normes de système de management ISO 27017, un code de pratique internationalement reconnu pour les contrôles de sécurité de l'information pour les services de cloud.
        <p>Cependant, étant donné la nature de la technologie de la communication et de l'information, et puisque l'utilisation d'Internet comporte des risques inhérents, bien que nous surveillions régulièrement les vulnérabilités et les attaques possibles, nous ne pouvons assurer ou garantir que ces informations fournies par HunyVerse ou stockées dans nos systèmes seront totalement exempts d'intrusion extérieur non autorisée, et nous ne pouvons assurer ou garantir que ces données ne peuvent être consultées, divulguées, altérées ou détruites par une violation de nos garanties physiques, techniques ou de gestion.</p>
        <h2 class="title">Traitement des données</h2>
        Nous ne collectons et ne traitons les données personnelles vous concernant que dans les cas où nous disposons de bases légales. Les bases légales comprennent le consentement (lorsque vous avez donné votre accord), le contrat et d'autres intérêts légitimes. Ces intérêts légitimes comprennent votre protection, la notre, celle des autres membres et des tiers, le respect de la législation en vigueur, l'autorisation et l'administration de nos activités, la gestion des transactions d'entreprise, la compréhension et l'amélioration générales de nos processus internes et de nos relations avec les utilisateurs, et la possibilité pour nous et les autres utilisateurs de HunyVerse de se connecter avec vous pour échanger des informations, à condition que ce qui précède protège de manière adéquate vos droits et libertés.
        <p>Lorsque nous comptons sur votre consentement pour traiter des données personnelles, vous avez le droit de retirer ou de refuser votre consentement à tout moment et lorsque nous nous appuyons sur des intérêts légitimes, vous avez le droit de vous opposer. Si vous avez des questions sur les bases légales sur lesquelles nous collectons et utilisons vos données personnelles, veuillez nous contacter à <a href="mailto:contact@hunyverse.com" target="_blank">contact@hunyverse.com</a> .</p>
        <h2 class="title">Collecte d'Informations de tiers</h2>
        Cette politique de confidentialité ne s'applique qu'aux sites et applications appartenant à HunyVerse et exploités par HunyVerse. Veuillez garder à l'esprit que HunyVerse peut contenir des liens vers d'autres sites ou applications. Il vous appartient d'examiner les déclarations de confidentialité et les politiques des autres sites Web que vous choisissez de lier à HunyVerse, afin de comprendre comment ces sites collectent, utilisent et stockent vos informations. Nous ne sommes pas responsables des déclarations de confidentialité, des politiques ou du contenu d'autres sites ou applications, y compris les sites Web que vous liez vers ou depuis HunyVerse. Les sites Web contenant du co-branding (référençant notre nom et le nom d'un tiers) contiennent du contenu fourni par des tiers et non par nous.
        <p>Si vous choisissez de relier HunyVerse à d'autres sites web, applications et services ou à des profils que vous avez avec des applications de tiers, vous fournirez les données personnelles stockées sur ces applications à HunyVerse. Par exemple, vous pouvez créer un nouveau compte de membre HunyVerse en liant votre compte Facebook à HunyVerse, fournissant ainsi à HunyVerse les informations personnelles que vous avez choisi de partager par le biais de Facebook. Vous pouvez révoquer le lien avec ces comptes et les applications tierces en modifiant vos paramètres avec ces applications.</p>
        <h2 class="title">Changements de notre politique de confidentialité</h2>
        Nous pouvons mettre à jour notre politique de confidentialité de temps en temps pour refléter les changements dans nos pratiques. Nous recherchons constamment de nouvelles façons d'offrir une version améliorée de HunyVerse et d'accroître l'engagement. Au fur et à mesure que nous améliorons HunyVerse, cela peut signifier la collecte de nouvelles données ou de nouvelles façons d'utiliser les données. Comme HunyVerse est dynamique et que nous cherchons continuellement à offrir de nouvelles fonctionnalités, nous pouvons être amenés à modifier notre collecte ou notre traitement des informations. Si nous collectons des données personnelles sensiblement différentes ou si nous modifions sensiblement la manière dont nous utilisons vos données, nous mettrons à jour la présente politique de confidentialité.
        <p>Nous publierons sur cette page toute modification de notre politique de confidentialité. Si nous apportons des modifications importantes à la manière dont nous traitons les informations personnelles de nos utilisateurs, nous vous informerons que la politique de confidentialité a été mise à jour. La date de la dernière modification de la politique de confidentialité est indiquée au début de cette politique confidentialité. Il vous incombe de veiller à ce que nous disposions d'une adresse électronique active et joignable et de consulter périodiquement la présente politique de confidentialité pour vérifier les changements éventuels.</p>
        <h2 class="title">Informations de contact</h2>
        Pour poser des questions ou faire des commentaires sur la présente politique de confidentialité et sur nos pratiques en matière de protection de la vie privée, vous pouvez nous contacter par courriel électronique à <a href="mailto:contact@hunyverse.com" target="_blank">contact@hunyverse.com</a>.
    </div>
</template>

<script>

export default {
    name: 'Privacy',
    mounted () {
        document.title = this.$t('privacy.title')
    }
}
</script>

<style lang="stylus" scoped>
.privacy {
    max-width: 1023px;
    margin: 0px auto 0px auto;
    padding: 30px 30px 30px 30px;
    text-align: left;
    font-size: 18px;
    color: #222;

    .logo {
        display: inline-flex;
        align-items: center;
        margin: 0px 0px 0px 0px;

        .image {
            max-width: 70px;
        }
        .text {
            margin: 0px 0px 0px 5px;
            font-family: 'Grand Hotel';
            font-weight: 300;
            font-size: 50px;
            color: #00aba9;
        }
    }

    .title {
        margin-top: 1.5rem;
    }
}
</style>
