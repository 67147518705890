<template>
    <div
        class="language-selector"
        @click="onClick"
    >
        <img class="flag" :src="`/pictures/flags/${selectedFlag}.png`"/>
        <div class="text">{{ selectedLabel }}</div>
        <div class="language-menu" :class="{'language-menu-open' : menuOpened}">
            <div
                class="language-item"
                v-for="(language, languageIndex) in languages"
                :key="languageIndex"
                @click="onLanguageChange(language)"
            >
                <img class="flag" :src="`/pictures/flags/${language.flag}.png`"/>
                <div class="text">{{ language.label }}</div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'LanguageSelector',
    props: {
        selected: {
            type: String
        }
    },
    data: () => ({
        languages: [
            { id: 'en', label: `English`, flag: 'US' },
            { id: 'fr', label: `Français`, flag: 'FR' },
            { id: 'es', label: `Español`, flag: 'ES' },
            { id: 'pt', label: `Português`, flag: 'BR' },
            { id: 'it', label: `Italiano`, flag: 'IT' },
            { id: 'ro', label: `Română`, flag: 'RO' },
            { id: 'jp', label: `日本語`, flag: 'JP' },
            { id: 'cn', label: `中国人`, flag: 'CN' },
            { id: 'tr', label: `Türkçe`, flag: 'TR' },
            { id: 'ru', label: `Pусский`, flag: 'RU' },
            { id: 'de', label: `Deutsch`, flag: 'DE' }
        ],
        menuOpened: false
    }),
    computed: {
        selectedLabel () {
            const selected = this.getSelectedLanguage()
            return selected.label
        },
        selectedFlag () {
            const selected = this.getSelectedLanguage()
            return selected.flag
        }
    },
    methods: {
        getSelectedLanguage () {
            return this.languages.find((language) => {
                return language.id == this.selected
            })
        },
        toggleOpenMenu () {
            this.menuOpened = !this.menuOpened
        },
        onClick (e) {
            e.stopPropagation()
            this.toggleOpenMenu()
        },
        onLanguageChange (language) {
            this.$emit('change', language)
        },
        onDocumentClick (e) {
            this.menuOpened = false
        }
    },
    beforeDestroy () {
        document.removeEventListener('click', this.onDocumentClick)
    },
    mounted () {
        document.addEventListener('click', this.onDocumentClick)
    }
}
</script>

<style lang="stylus" scoped>
$mobile = 1023px;
.language-selector {
    display: inline-flex;
    align-items: center;
    position: relative;
    color: rgba(255, 255, 255, 0.7);
    cursor: pointer;

    .flag {
        height: 30px;
    }
    .text {
        margin: 0px 0px 0px 10px;
        font-size: 16px;

        @media (max-width: $mobile) {
            color: white;
        }
    }

    .language-menu {
        position: absolute;
        left: 0px;
        top: 74px;
        width: 200px;
        padding: 10px 0px 10px 0px;
        border-radius: 10px;
        z-index: 10;
        opacity: 0;
        transform: scale(0);
        background: white;
        box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);
        transition: all 300ms cubic-bezier(0.34, 1.61, 0.7, 1);

        @media (max-width: $mobile) {
            left: -46px;
            top: 35px;
        }

        .language-item {
            display: flex;
            align-items: center;
            height: 42px;

            .flag {
                width: 30px;
                margin: 0px 0px 0px 15px;
            }
            .text {
                margin: 0px 0px 0px 10px;
                font-size: 16px;
                color: #333333;
            }
        }
        .language-item:hover {
            background: #00aba9;

            .text {
                color: #FFFFFF;
            }
        }
    }
    .language-menu-open {
        transform-origin: 29px -50px;
        transform: scale(1);
        opacity: 1;
        filter: none;
    }
}
.language-selector:hover {

    .text {
        color: #FFFFFF;
    }
}
</style>
