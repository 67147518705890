<template>
    <div class="image-slider">
        <div class="black-overlay"></div>
        <div
            class="slider-image"
            :style="`background-image: url(${currentImage});`"
        />
    </div>
</template>

<script>

export default {
    name: 'ImageSlider',
    data: () => ({
        images: [
            '/pictures/splashs/0.jpg',
            '/pictures/splashs/1.jpg',
            '/pictures/splashs/2.jpg',
            '/pictures/splashs/3.jpg',
            '/pictures/splashs/4.jpg',
            '/pictures/splashs/5.jpg',
            '/pictures/splashs/6.jpg',
            '/pictures/splashs/7.jpg',
            '/pictures/splashs/8.jpg',
            '/pictures/splashs/9.jpg',
            '/pictures/splashs/10.jpg',
            '/pictures/splashs/11.jpg',
            '/pictures/splashs/12.jpg',
            '/pictures/splashs/13.jpg',
            '/pictures/splashs/14.jpg',
            '/pictures/splashs/15.jpg',
            '/pictures/splashs/16.jpg',
            '/pictures/splashs/17.jpg',
            '/pictures/splashs/18.jpg',
            '/pictures/splashs/19.jpg',
            '/pictures/splashs/20.jpg',
            '/pictures/splashs/21.jpg',
            '/pictures/splashs/22.jpg',
            '/pictures/splashs/23.jpg'
        ],
        currentNumber: Math.round(Math.random() * 23),
        timer: null
    }),
    computed: {
        currentImage () {
      	    return this.images[this.currentNumber]
        }
    },
    methods: {
        startRotation () {
            this.timer = setInterval(this.next, 10000)
        },
        stopRotation () {
            clearTimeout(this.timer)
            this.timer = null
        },
        next () {
            if (this.currentNumber < this.images.length - 1) {
                this.currentNumber++
            }
            else {
                this.currentNumber = 0
            }
        }
    },
    mounted () {
        this.startRotation()
    }
}
</script>

<style lang="stylus" scoped>
.image-slider {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;

    .black-overlay {
        position: fixed;
        z-index: 10;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        background: black;
        opacity: .35;
    }

    .slider-image {
        position: fixed;
        z-index: 5;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
    }
}
</style>
