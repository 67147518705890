<template>
    <div class="apple-store-btn">
        <div class="btn-left">
            <svg class="btn-logo" version="1.1" id="Ebene_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="708.662px" height="708.662px" viewBox="0 0 708.662 708.662" enable-background="new 0 0 708.662 708.662"
                xml:space="preserve">
                <g>
                    <g id="g25472" transform="translate(19.48863,19.49672)">
                        <path id="path190" fill="#FFFFFF" d="M438.59,109.303c23.751-28.768,39.785-68.768,35.368-108.638
                        c-34.215,1.373-75.627,22.809-100.193,51.576c-21.991,25.425-41.286,66.153-36.058,105.21
                        C375.867,160.409,414.836,138.027,438.59,109.303"/>
                    </g>
                    <g id="g186" transform="matrix(54.85033,0,0,-54.85033,-1411.313,6454.729)">
                        <path id="path188" fill="#FFFFFF" d="M35.575,110.939c-0.014,1.57,1.282,2.323,1.34,2.36c-0.729,1.067-1.864,1.213-2.269,1.23
                        c-0.965,0.098-1.884-0.568-2.374-0.568c-0.489,0-1.246,0.554-2.046,0.539c-1.054-0.016-2.024-0.612-2.566-1.555
                        c-1.094-1.897-0.279-4.709,0.786-6.248c0.521-0.753,1.142-1.601,1.958-1.57c0.786,0.031,1.083,0.508,2.032,0.508
                        c0.949,0,1.216-0.508,2.047-0.493c0.845,0.016,1.381,0.769,1.899,1.524c0.597,0.874,0.844,1.72,0.858,1.764
                        C37.221,108.438,35.592,109.063,35.575,110.939"/>
                    </g>
                </g>
            </svg>
        </div>
        <div class="btn-right">
            <div class="btn-title">{{ $t('home.download') }}</div>
            <div class="btn-legend">{{ $t('home.on-apple-store') }}</div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'AppleStoreBtn'
}
</script>

<style lang="stylus" scoped>
    .apple-store-btn {
        display: inline-flex;
        align-items: center;
        margin: 0px 10px 20px 10px;
        padding: 10px 30px 10px 30px;
        border-radius: 45px;
        background: #3b5a9b;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.33);
        transition: background-color 0.2s;

        .btn-left {
            .btn-logo {
                width: 45px;
                height: 45px;
            }
        }
        .btn-right {
            margin: 0px 10px 0px 10px;
            text-align: left;
            color: #FFFFFF;

            .btn-title {
                font-size: 24px;
            }
            .btn-legend {
                font-size: 16px;
            }
        }
    }
    .apple-store-btn:hover {
        background: #30497d;
    }
</style>
